<template>
<div class="data-checking" v-if="fulldata">
    <div class="checking">
        <p class="loader-icon"><i class="el-icon-loading"></i></p>
        <p> Sizning Ma'lumotlaringiz tekshirilmoqda...</p>
    </div>
</div>
<div class="data-checking" v-else>
    <div class="checking">
        <p>Сизнинг маълумотларингиз топилмади илтимос тизим админстаторлари билан боғланинг!</p>
         
            <button class="backbutton" @click="Close()"><i class="el-icon-close"></i> Yopish </button>
       
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            authData: {
                state: this.$route.query && this.$route.query.state ? this.$route.query.state : '',
                auth_code: this.$route.query && this.$route.query.code ? this.$route.query.code : ''
            },
            fulldata: true,

        }
    },
    methods: {
        login() {
            axios.defaults.headers.common["Authorization"] = null;
            axios.post("/oauth/grant/", this.authData)
                .then(res => {
                    console.log(res.data)
                    if (res.data.role.id && res.data.organization.id) {
                        localStorage.token = res.data.access;
                        localStorage.refresh = res.data.refresh;
                        axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.access}`;
                        this.$store.dispatch("get_me");
                        const role = res.data.role.name;
                        localStorage.role = res.data.role.name;
                        this.$store.commit('set_role', role)
                         
                        this.$router.push('/')
                    } else {
   
                        this.fulldata = false;
                    }

                })
                .catch((e) => {
                    this.fulldata = false;
                })
        },
        Close(){ 
            close()
        }
    },
    mounted() {
        if (this.$route.query && this.$route.query.state && this.$route.query.code) {
            this.authData.state = this.$route.query.state;
            this.authData.auth_code = this.$route.query.code;
            this.login()
        } else {
            this.$router.push('/login')
        }

    }
}
</script>

<style lang="scss">
.data-checking {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.checking {
    max-width: 500px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    text-align: center;

    .loader-icon {
        i {
            font-size: 50px;
            font-weight: 600;
            margin-bottom: 20px;
        }
    }

   

    .backbutton {
        padding: 10px 20px;
        background: #004787;
        font-weight: 600;
        border-radius: 5px;
        margin-top: 20px;
        text-decoration: none;
        color: #fff !important;
        i {
            margin-right: 5px;
            color: #fff !important;
            font-weight: 600;
        }
    }
}
</style>
